<script>
  import { mode } from "../store/auth.store";

  let mode_value;

  mode.subscribe((value) => {
    mode_value = value;
  });
</script>

{#if mode_value === "signUp"}
  <div class="text-center mt-4 fade-in">
    <h1 class="h2">Create an account on tenx-live</h1>
    <p class="lead">Welcome!</p>
  </div>
{:else}
  <div class="text-center mt-4 fade-in">
    <h1 class="h2">Sign In to tenx-live</h1>
    <p class="lead">Welcome back!</p>
  </div>
{/if}
