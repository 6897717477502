<script>
  // sveltefire
  import { FirebaseApp, User } from "sveltefire";

  // firebase
  import firebase from "firebase/app";
  import "firebase/auth";
  import "firebase/database";

  // routes
  import Auth from "./routes/Auth.svelte";
  import Home from "./routes/Home.svelte";

  let firebaseConfig = {
    apiKey: "AIzaSyAf9toj2SKex91QrwW4ll5zL6YYQSxTSOE",
    authDomain: "tenx-live.firebaseapp.com",
    databaseURL: "https://tenx-live-default-rtdb.firebaseio.com",
    projectId: "tenx-live",
    storageBucket: "tenx-live.appspot.com",
    messagingSenderId: "1041718344772",
    appId: "1:1041718344772:web:bd038326bb4eb6cbf0d011",
  };

  firebase.initializeApp(firebaseConfig);
</script>

<main>
  <FirebaseApp {firebase}>
    <User persist={localStorage} let:user let:auth>
      <div slot="signed-out">
        <Auth {firebase} />
      </div>
      <Home {firebase} {user} />
    </User>
  </FirebaseApp>
</main>
