<script>
  import { clickOutside } from "../directives/clickOutside.js";
  export let firebase;
  export let user;

  // date
  const year = new Date(Date.now()).getFullYear();
  // realtime data
  let sensorData;
  const sensordataRef = firebase.database().ref("sensordata");
  sensordataRef.on("value", (snapshot) => {
    const data = snapshot.val();
    sensorData = data;
  });

  // auth
  function signOut(event) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorMessage);
      });
  }

  // dropdown
  let showDropdown = false;
  function toggleDropdown() {
    showDropdown = !showDropdown;
  }
  function hideDropdown() {
    showDropdown = false;
  }
</script>

<!-- <button >Sign out</button>
{user.uid}

-->
<div class="wrapper">
  <div class="main">
    <nav class="navbar navbar-expand navbar-light navbar-bg fade-in">
      <ul class="navbar-nav navbar-align">
        <li class="nav-item dropdown">
          <p
            class="nav-link dropdown-toggle d-inline-block mb-0 cursor-pointer"
            data-toggle="dropdown"
            on:click={toggleDropdown}
            use:clickOutside
            on:click_outside={hideDropdown}
          >
            <span class="text-dark">{user.email}</span>
          </p>
          <div
            class="dropdown-menu dropdown-menu-right"
            class:show={showDropdown}
          >
            <button class="dropdown-item" on:click|once={signOut}
              >Log out</button
            >
          </div>
        </li>
      </ul>
    </nav>

    <main class="content">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12">
            {#if sensorData}
              <div class="row fade-in">
                <div class="col-12">
                  <div class="w-100 d-flex justify-content-center flex-wrap">
                    <div class="card mx-2 fade-in">
                      <div class="card-body">
                        <h5 class="card-title mb-4">Xval</h5>
                        <h1 class="mt-1 mb-3">{sensorData.xval}</h1>
                        <div class="mb-1">
                          <span class="text-danger">
                            <i class="mdi mdi-arrow-bottom-right" /> -3.65%
                          </span>
                          <span class="text-muted">Since last week</span>
                        </div>
                      </div>
                    </div>
                    <div class="card mx-2 fade-in">
                      <div class="card-body">
                        <h5 class="card-title mb-4">Yval</h5>
                        <h1 class="mt-1 mb-3">{sensorData.yval}</h1>
                        <div class="mb-1">
                          <span class="text-success">
                            <i class="mdi mdi-arrow-bottom-right" /> 5.25%
                          </span>
                          <span class="text-muted">Since last week</span>
                        </div>
                      </div>
                    </div>
                    <div class="card mx-2 fade-in">
                      <div class="card-body">
                        <h5 class="card-title mb-4">Zval</h5>
                        <h1 class="mt-1 mb-3">{sensorData.zval}</h1>
                        <div class="mb-1">
                          <span class="text-danger">
                            <i class="mdi mdi-arrow-bottom-right" /> -2.25%
                          </span>
                          <span class="text-muted">Since last week</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {:else}
              <div class="text-center my-4">
                <img
                  class="rotate"
                  src="./assets/icons/loader.svg"
                  alt="Loader icon"
                />
              </div>
            {/if}
          </div>
        </div>
      </div>
    </main>

    <footer class="footer fade-in">
      <div class="container-fluid">
        <div class="row text-muted">
          <div class="col-6 text-left">
            <p class="mb-0">
              <a href="index.html" class="text-muted"
                ><strong>Tenx live sensor</strong></a
              >
              &copy; {year}
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
