<script>
  import { mode } from "../store/auth.store";

  let mode_value;

  mode.subscribe((value) => {
    mode_value = value;
  });

  function toggle() {
    if (mode_value === "signIn") {
      mode.set("signUp");
    } else {
      mode.set("signIn");
    }
  }
</script>

<p class="text-primary cursor-pointer text-right" on:click={toggle}>
  {#if mode_value === "signUp"}
    You already have an account? Sign in
  {:else}
    You don't have an account? Sign up
  {/if}
</p>
