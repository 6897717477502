<script>
  export let firebase;

  // store
  import { user, mode } from "../store/auth.store.js";

  // components
  import AuthTitle from "../components/AuthTitle.svelte";
  import AuthToggle from "../components/AuthToggle.svelte";

  let authErrorMessage = "";
  let authErrorCode = "";
  let loadingRequest = false;
  let requiredEmail = false;
  let requiredPassword = false;

  function dimissAuthError() {
    authErrorMessage = "";
    authErrorCode = "";
    requiredEmail = false;
    requiredPassword = false;
  }

  function resetForm() {
    user.set({
      email: "",
      password: "",
    });
  }

  // auth mode
  let mode_value;
  mode.subscribe((value) => {
    mode_value = value;
  });

  function handleSubmit(event) {
    dimissAuthError();

    const formData = new FormData(event.target);
    const formUser = {};

    for (const [k, v] of formData.entries()) {
      formUser[k] = v;
    }

    if (!formUser.email) {
      requiredEmail = true;
    }

    if (!formUser.email) {
      requiredPassword = true;
    }

    if (requiredEmail || requiredPassword) {
      return;
    }

    if (mode_value === "signUp") {
      loadingRequest = true;
      firebase
        .auth()
        .createUserWithEmailAndPassword(formUser.email, formUser.password)
        .then((_) => {
          resetForm();
          loadingRequest = false;
        })
        .catch((error) => {
          authErrorCode = error.code;
          authErrorMessage = error.message;
          loadingRequest = false;
        });
    } else if (mode_value === "signIn") {
      loadingRequest = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(formUser.email, formUser.password)
        .then((_) => {
          resetForm();
          loadingRequest = false;
        })
        .catch((error) => {
          authErrorCode = error.code;
          authErrorMessage = error.message;
          loadingRequest = false;
        });
    }
  }
</script>

<main class="d-flex w-100">
  <div class="container d-flex flex-column">
    <div class="row vh-100">
      <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
        <div class="d-table-cell align-middle">
          <AuthTitle />

          <div class="card fade-in-down">
            <div class="card-body">
              <div class="m-sm-4">
                <form action="#xxx" on:submit|preventDefault={handleSubmit}>
                  <div class="mb-3">
                    <label class="form-label">Email</label>
                    <input
                      class:is-invalid={requiredEmail}
                      class="form-control form-control-lg"
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      bind:value={$user.email}
                    />
                    <div class="invalid-feedback">The email is required.</div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Password</label>
                    <input
                      class:is-invalid={requiredPassword}
                      class="form-control form-control-lg is-invalid"
                      type="password"
                      name="password"
                      placeholder="Enter your password"
                      bind:value={$user.password}
                    />
                    <div class="invalid-feedback">
                      The password is required.
                    </div>
                  </div>
                  <AuthToggle />

                  {#if authErrorMessage}
                    <div
                      class="alert alert-danger alert-dismissible mt-3 fade-in"
                      role="alert"
                    >
                      <button
                        on:click={dimissAuthError}
                        type="button"
                        class="btn-close"
                        data-dismiss="alert"
                        aria-label="Close"
                      />
                      <div class="alert-icon">
                        <i class="far fa-fw fa-bell" />
                      </div>
                      <div class="alert-message pr-4">
                        <strong>Error!</strong>
                        {authErrorMessage} (code: {authErrorCode})
                      </div>
                    </div>
                  {/if}
                  {#if loadingRequest}
                    <div class="text-center my-4">
                      <img
                        class="rotate"
                        src="./assets/icons/loader.svg"
                        alt="Loader icon"
                      />
                    </div>
                  {:else}
                    <div class="text-center mt-3">
                      {#if mode_value === "signUp"}
                        <button class="btn btn-lg btn-primary fade-in">
                          Sign Up
                        </button>
                      {:else}
                        <button class="btn btn-lg btn-primary fade-in">
                          Sign In
                        </button>
                      {/if}
                    </div>
                  {/if}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
